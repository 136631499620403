import React, {useState} from "react";
import "./OurCapabilities.css";
import UXDriven from "../assets/UX-Driven.png";
import Fast from "../assets/Fast.png";
import Euro from "../assets/Euro.png";
import Transparency from "../assets/Transparency.png";


export function OurCapabilities() {
  return (
    <div className='OurCapabilities'>
      <h1 className="OurCapabilities-title"><span className="borderText">Our</span>Capabilities</h1>
      <div className="OurCapabilities-cards">
      <div className="OurCapabilities-cards-card">
          <div className="OurCapabilities-cards-card-number"><h1>1</h1></div>
          <div><h1 className="OurCapabilities-cards-card-title">Design Thinking &<br/>Customer Centricity</h1>
          <p className="OurCapabilities-cards-card-text">
          Our experience with consumer interviews, rapid prototyping, A/B-testing and consumer validation sessions allow us to identifying the most desirable ideas in no time. 
          </p></div>
        </div>
        <div className="OurCapabilities-cards-card">
          <div className="OurCapabilities-cards-card-number"><h1>2</h1></div>
          <div><h1 className="OurCapabilities-cards-card-title">Mock-up Design & <br/>Creative Branding</h1>
          <p className="OurCapabilities-cards-card-text">
          Our proficiency in design tools such as Figma, Photoshop, Illustrator and Blender allow us to quickly bring a branded version of your digital or physical product to life. 
          </p></div>
        </div>
        <div className="OurCapabilities-cards-card">
          <div className="OurCapabilities-cards-card-number"><h1>3</h1></div>
          <div><h1 className="OurCapabilities-cards-card-title">Web Design & <br/>Development</h1>
          <p className="OurCapabilities-cards-card-text">
          Our expertise with multiple code and no-code technology stacks enable us to build your first, easy-to-maintain website or webshop capable of validating your vision and realize your first sales.
          </p></div>
        </div>
        <div className="OurCapabilities-cards-card">
          <div className="OurCapabilities-cards-card-number"><h1>4</h1></div>
          <div><h1 className="OurCapabilities-cards-card-title">Prototyping & <br/>MVP Development</h1>
          <p className="OurCapabilities-cards-card-text">
          Our expertise with React, Node.js, Python, MongoDB, Express, Firebase allow us to quickly turn ideas into functional prototypes and minimal vible products, ready to convince even the most critical minds.
          </p></div>
        </div>
      </div>
    </div>
  )
};
