import React from "react";
import "./Promises.css";
import Discover from "../assets/Discover.png";
import Build from "../assets/Build.png";
import Validate from "../assets/Iterate.png";

class Promises extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='Promises' id='Approach'>
        <div className="Promises-intro">
          <h1><span className="borderText">Our</span>Promises</h1>
          <p>We provide you with a superpower. The power to identify and develop brand boosting concepts, fastforward into the future and experience customer reactions, before making any unnecessary and expensive commitments.</p>
        </div>
        <div className="Promises-overview">
          <div className="Promises-overview-card"><img src={Discover} alt="Discover"></img><h4 style={{borderColor: "rgb(255,255,255)"}}>DISCOVER</h4><p>Through design thinking and futures thinking principles, we help you spark your imagination, understand your (future) customers, and identify the most promising opportunities to grow your brand.
            </p></div>
          <div className="Promises-overview-card"><img src={Build} alt="Build"></img><h4 style={{borderColor: "rgb(255,255,255)"}}>BUILD</h4><p>We help you build the ideal mockups, prototypes, minimal viable products, and marketing materials to quickly validate some critical assumptions and de-risk your ideas.
</p></div>
          <div className="Promises-overview-card"><img src={Validate} alt="Validate"></img><h4 style={{borderColor: "rgb(255,255,255)"}}>ITERATE</h4><p>We help you raise the additional funds to continue iterating until you have created a unique marble, capable of convincing even the most critical minds.
</p></div>
        </div>
  </div>  
    
    );
  }
}

export default Promises;
