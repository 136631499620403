import React from "react";
import "./marbles.css";
import MarblesTile from "./MarblesTile";
import { v4 as uuidv4 } from 'uuid';

class Marbles extends React.Component {
  static defaultProps = {
    marbles : 
    [
      {title: `MonthlyScent`, description: `Exploring perfumes from the comfort of your home`, endpoint: '/monthlyscent', id:uuidv4()},
      {title: `StandZero`, description: `Record & live-stream amateur sport games`, endpoint: '/standzero', id:uuidv4()}
    ],
    futureMarbles: 
    [
      {title: `SongRqst`, description: `Allowing party guests to request and upvote their favorite songs`,id:uuidv4()},
      {title: `KikoFood`, description: `Deliciously healthy snacks for kids with an engaging brand experience`,id:uuidv4()},
      {title: `RebornGames`, description: `Augmenting the traditional board game experience`,id:uuidv4()},
      {title: `RosieDrinks`, description: `A refreshing, cold rosé, popping-up whenever you need it the most`,id:uuidv4()},
      {title: 'Brilliants', description: 'Collectively solving the biggest business challenges, one micro-challenge at a time.', id: uuidv4()},
      {title: 'LilaLinen', description: '100% duurzaam, 100% lokaal, 100% linnen slaapzakjes', id: uuidv4()},
      {title: 'Captic', description: 'See more with an affordable, AI-driven food grade camera system', id: uuidv4()},
    ]
  }  

    render() {
      return (
        <div className='Marbles' id='Marbl3s'>
          <div className='Marbles-title'>
            <h1><span className="borderText">Our</span><br/>recent work</h1>
            <p>We love developing both physical and digital concepts but wherever we can bring them together, that's where we get really excited. Check out some of our most recent marbles below.</p>
          </div>
          <div className='Marbles-tiles'>
            {this.props.marbles.map((marble) => 
            (<a href={marble.endpoint}><MarblesTile title={marble.title} description={marble.description} key={marble.id} caseText='Check out our case' /></a>)
            )}
            {this.props.futureMarbles.map((marble) => 
            <MarblesTile title={marble.title} description={marble.description} key={marble.id} caseText='Case coming soon' />
            )}
          </div>
          <button className="Marbles-overlay-content-button"><a className="emailLink" href="mailto:gilles@marbl3s.be">Contact Us</a></button>
        </div>
      );
    }
}

export default Marbles;