import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
import {getStorage} from 'firebase/storage'
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDR9vPpieEXHJD0XaBP3GaYc2vGBE63d3k",
  authDomain: "marbl3s-51c14.firebaseapp.com",
  projectId: "marbl3s-51c14",
  storageBucket: "marbl3s-51c14.appspot.com",
  messagingSenderId: "1019776112792",
  appId: "1:1019776112792:web:38d0b642c0a4d2ab6a98ed",
  measurementId: "G-31LFEHQ53D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);



  // initialize firebase

  const firebaseApp = initializeApp(firebaseConfig);

  
  // initialize db service
  
  const db = getFirestore(firebaseApp)

  //initialize storage service 

  const storage = getStorage(firebaseApp)

  // initialize firebase auth service

  const auth = getAuth(firebaseApp)

  // initialize firebase anaylitcs

  const analytics = getAnalytics(app);

  export {db, auth, storage, analytics}