import React,{useEffect} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./App.css";
import {Homepage} from "./pages/Homepage.js";
import {MonthlyScent} from "./pages/RefCase_MonthlyScent.js";
import {StandZero} from "./pages/RefCase_StandZero.js";
import Footer from "./components/Footer.js";

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/monthlyscent" element={<MonthlyScent />} />
            <Route path="/standzero" element={<StandZero />} />
          </Routes>
        </BrowserRouter>
      </header>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
