import React, {useState} from 'react';
import './ContactForm.css';
import {Link} from 'react-router-dom';

// friebase imports
import {db} from '../firebase/config'
import {collection, addDoc} from 'firebase/firestore'

export function ContactForm() {
    const [formActive, setFormActive] = useState(false);
    const [email, setEmail] = useState("");
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    
    const handleClick = () => {
        console.log("the button was clicked");
        setFormActive(true);
        console.log(formActive);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setEmailSubmitted(true);
        setFormActive(false);
        setEmail("");
        try {
            const ref = collection(db, 'marbleous');
            const orderLead = {email: email, leadType:"MarbleousConcepts"};
            await addDoc(ref, orderLead);
            console.log("email is submitted");
        } 
        catch (e) {
          console.log('Error')
        }  
    };

    return(
        <div className="ContactForm">
            {formActive && (<form className="ContactForm-form" onSubmit={handleSubmit}>
                <div className="ContactForm-form-label"><label htmlFor="email">Contact Form</label></div>
                <div><p>Thanks for showing interest. Please share your e-mail address below and we'll get back to you as soon as possible.</p></div>
                <div className="ContactForm-form-input"><input type="text" id="email" name="email" placeholder="email" value={email} onChange={handleEmailChange} />
                <button className="ContactForm-form-submitButton" >Submit</button></div>
            </form>)}
            {emailSubmitted && (<p className="EmailSubmittedText">Thanks! We've received your email address well. <br/>We'll contact you as soon as possible.</p>)}
            {!formActive && !emailSubmitted && (
                <div className="ContactForm-introductionPage-buttons">
                    <button className="ContactForm-introductionPage-buttons-contactButton" onClick={handleClick}>Contact Us</button>
                    <button className="ContactForm-introductionPage-buttons-ourWorkButton" ><a href="#Marbl3s">Check our work</a></button>
                </div>
            )}
        </div>
    )
}