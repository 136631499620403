import React, {Component} from 'react';
import './Pricing.css'

class Pricing extends Component {
    render(){
        return (
            <div className='Pricing'>
                <div className='Pricing-textbox' id="Pricing">
                    <h1><span className="borderText">Our</span>Pricing</h1>
                    <p>During an iterative design cycle of three weeks, a team of seasoned entrepreneurs, designers and developers, co-creates the necessary branding, mock-ups, prototypes and MVP to bring your vision to life and get the necessary customer feedback for future iterations.</p>
                    <p>Contact us to get an exact quote for your needs.</p>
                    <div className = "Pricing-conditions">
                        <div className = "Pricing-conditions-card">
                            <p className = "Pricing-conditions-card-text">Price Per Iteration:</p>
                            <p className = "Pricing-conditions-card-number">€5,000-€10,000</p>
                        </div>
                        <div className = "Pricing-conditions-card">
                            <p className = "Pricing-conditions-card-text">Iteration Timeline:</p>
                            <p className = "Pricing-conditions-card-number">3 weeks</p>
                        </div>
                    </div>
                    <button className='Pricing-button' href="mailto:gilles.rossou@marbleousconcepts.com">CONTACT US</button>
                </div>
            </div>
        )
    }
}

export default Pricing