
import React from "react";
import "./Homepage.css";
import "../components/navigation.css";
import Navigation from "../components/navigation.js";
import {Introduction} from "../components/Introduction.js";
import Promises from "../components/Promises.js";
import MobileCapabilityCarousel from "../components/MobileCapabilityCarousel.js";
import Marbles from "../components/marbles.js"
import Pricing from "../components/Pricing.js";
import HowWeHelp from "../components/HowWeHelp.js";
import { OurCapabilities } from "../components/OurCapabilities";

export function Homepage(){
    return (
        <div className='Homepage'>
            <nav className="App" id='navigationBar'>
                <Navigation />
            </nav>
            <Introduction className="introductionPage"/>
            <HowWeHelp />
            <OurCapabilities />
            <MobileCapabilityCarousel />
            <Marbles />
            <Promises />
            <Pricing />
        </div>
    )
}