import React, {Component} from "react";
import DesirabilityFeasibilityMatrix from "../assets/DesirabilityFeasibilityMatrix.png";
import appVisual from "../assets/Camera.png";
import technologyStack from"../assets/TechnologyStack.png";
import back from "../assets/back.svg";
import "./RefCase_StandZero.css";

export function StandZero(){
    return (
        <div className="StandZero">
            <div className="StandZero-case">
                <div className="backButton">
                    <a href="/#Marbl3s"><img src={back} alt="back button"/>Back</a>
                    </div>
                <div className="StandZero-case-title">
                    <h3>CASE STUDY</h3>
                    <h1>Live broadcasting local sports teams</h1>
                </div>
                <div className="DeviderImage"></div>
                <div className="StandZero-case-introduction">
                    <h1>Changing the local sports team fan experience</h1>
                    <p>Sports is a massive industry growing with a rate of 10% per year. Despite this growth trend, local sports teams are struggling. They cannot compete with the unique fan engagement that professional teams offer: replays life in the stadium, life game stats, game summaries, ability to follow games life wherever, whenever,...</p>
                    <p>Our client vision is to augment the fan experience in the amateur leagues, to a level above and beyond what fans expect from a 21st century fan experience.</p>
                </div>
                <div className="StandZero-case-details">
                    <div className="StandZero-case-details-title">
                        <h2>The Challenge</h2>
                    </div>
                    <div className="StandZero-case-details-text">
                        <p>Due to its convenience and the level of fan engagement, an increasing amount of football fans are following their favorite professional teams from the comfort of their home instead of going to watch a game of their local amateur team around the corner.</p>
                        <p>Our client wants to help teams differentiates themselves from other local teams by offering their fans the opportunity to follow games life from the comfort of their home. For those who prefer the stadium experience of watching a game life, local sport team fans will also receive the ability to watch game replays during the game and share them immediately with whom they see fit.</p>
                        <p>To do so, Marbl3s was asked to build an Minimal Viable Product which allows to automatically record and broadcast games life on a dedicated streaming platform. This MVP was used to validate the desirability of the solution. If there would be an appetite for such a solution, the MVP will be further scaled to allow life broadcasting to a large amount of fans in different sports.</p>
                    </div>
                    <div className="StandZero-case-details-title">
                        <h2>Our Approach</h2>
                    </div>
                    <div className="StandZero-case-details-text">
                        <p>Before starting the development of a Minimal Viable Product, we first wanted to make sure our solution focuses on the right customer needs and features. To do so, we developed some branded concept mock-ups and conducted a couple of validation interviews with some of the envisioned end-users. This gave us and our client the assurence that we were working on a solution worthwhile persuing.</p>
                        <img src={appVisual} alt="Product Visual"></img>
                        <p>In parallel with assessing the desirability of some of the envisioned product features, we assessed the feasibility of some of the features by looking at the technology landscape and potential third party solution providers.</p>
                        <img src={technologyStack} alt="Product Visual"></img>
                        <p>This allowed us to prioritize the must-have product features and potentially easy-to-implement delighters, above the difficult to implement "nice-to-have" features, which could potentially delight end-users in future product releases.</p>  
                        <img src={DesirabilityFeasibilityMatrix} alt="Desirability and Feasibility Matrix" id="DesirabilityFeasibilityMatrix"></img>
                        <p>Once we agreed with our client on the minimal viable product's feature set and technology stack, we started the development of the minimal viable product in close collaboration with our customer. Through iterative sprint sessions, we finalized our minimal viable product in a matter of weeks ready to be tested by our envisioned end-users. To do so, we released a small online marketing campaign addressing fans following the local teams facebook fan page. In a matter of weeks, over 300 fans tested the system. Currently our client is using the results of this successful project to raise the necessary funds to furhter develop their solution into a scalable solution which can be licenced by a large amount of teams in local leagues.</p>
                    </div>
                    <div className="StandZero-case-details-title">
                        <h2>The Results</h2>
                    </div>
                    <div className="StandZero-case-details-text">
                        <p>In less than 8 weeks, we were able to identify, build and validate an initial minimal viable product of the StandZero fan app. Through our unique appoach of rapid prototyping and MVP development, we were able to help our client:
                            <ul>
                                <li>develop the "StandZero" branding around their unique vision.</li>
                                <li>identify and prioritize a list of "must have" and "nice to have" features for their product development roadmap.</li>
                                <li>identify the required partners and components needed to build a minimal viable product.</li>
                                <li>build a minimal viable product capable of showcasing the real value of their concept, without wasting valuable resources and budget on unnecessary bells and whistles.</li>
                                <li> gather real world evidence on the desirability and feasibility of the envisioned "StandZero" concept, tremendously reducing the cost and risk of building a scalable, viable business around their concept.</li>
                                <li>showcase the value of their concept towards potential investors with real world evidence.</li>
                            </ul> 
                        Check out the details of the envisioned concept at <a href="https://www.standzero.com" target="_blank" rel="noopener noreferrer">www.standzero.com</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}