import { Cases } from '@mui/icons-material';
import React, {useState, useEffect} from 'react';
import './HowWeHelp.css';
import Desirability from "../assets/DiscoverAstro.png";
import Feasibility from "../assets/BuildAstro.png";
import Funds from "../assets/IterateAstro.png";

function HowWeHelp() {
    const [cases, setCases] = useState([{number:"01",title:"Assess Market Need", text:"Initial customer research is hardly enough to make large investment decisions. Test ideas with Mockups, Prototypes and MVPs and get valuable feedback - that’s how you derisk your investments.", id:"desirability", image:Desirability},
    {number:"02",title:"Check Feasibility", text:"There is no sense to make a fully working product until you’re sure that all the features are technically possible. Test scalability, 3rd party integrations and algorithm realization first.", id:"feasibility", image:Feasibility},{number:"03",title:"Raise Funds", text:"With interest rates on the rise, external and internal investors are no longer giving investments or budget for just a vague idea or vision. You have to let them experience your vision and show you have made your first product steps.", id:"invest", image:Funds}])
    const [screenWidth,setScreenWidth]=useState(1000);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            console.log(window.innerWidth);
          };
        window.addEventListener('resize', handleResize);
      },[]);
    return (
        <div className='HowWeHelp'>
            <div className='HowWeHelp-title'>
                <h1><span className="borderText">We make</span> <br/>Mock-ups, Prototypes<br/> and MVP's</h1>
            </div>
            <div className='HowWeHelp-cases'>
                <h2>Three usecases where marbl3s can help - </h2>
                {cases.map(item => (
                    <div className = "HowWeHelp-cases-card" id={item.id}>
                        <div className = "HowWeHelp-cases-card-number">
                        {item.number}
                        </div>
                        <div className = "HowWeHelp-cases-card-title">
                        {item.title}
                        </div>
                        <div className = "HowWeHelp-cases-card-text">
                        {item.text}
                        </div>
                    </div>
                    )
                    )}
                </div>
            </div>
        )
    }

export default HowWeHelp