import React, {useState} from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from 'react-swipeable-views-utils';
import './MobileCapabilityCarousel.css';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const cards = [
  {
    number: "1",
    title: "Design Thinking & Customer Centricity",
    content: "Our experience with consumer interviews, rapid prototyping, A/B-testing and consumer validation sessions allow us to identifying the most desirable ideas in no time.",
    swipe:"- SWIPE >"
  },
  {
    number: "2",
    title: "Mock-up Design & Creative Branding",
    content: "Our proficiency in design tools such as Figma, Photoshop, Illustrator and Blender allow us to quickly bring a branded version of your digital or physical product to life. ",
    swipe:"< SWIPE >"
  },
  {
    number: "3",
    title: "Webdesign & Development",
    content: "Our expertise with multiple code and no-code technology stacks enable us to build your first, easy-to-maintain website or webshop capable of validating your vision and realize your first sales.",
    swipe:"< SWIPE >"
  },
  {
    number: "4",
    title: "Prototyping & MVP Development",
    content: "Our expertise with React, Node.js, Python, MongoDB, Express, Firebase allow us to quickly turn ideas into functional prototypes and minimal vible products, ready to convince even the most critical minds.",
    swipe:"< SWIPE -"
  }
];

function MobileCapabilityCarousel() {
  const [index, setIndex] = useState(0);
  const handleChangeIndex = index => {
    setIndex(index);
  };

  return (
    <div className="MobileCapabilityCarousel">
      <h1 className="MobileCapabilityCarousel-title"><span className="borderText">Our</span><br/>Capabilities</h1>
      <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
        {cards.map((card, i) => (
          <div key={i} style={{ padding: 20 }}>
              <div className="MobileCapabilityCarousel-card">
                  <div><h1 className="MobileCapabilityCarousel-card-number">{card.number}</h1></div>
                  <div className="MobileCapabilityCarousel-card-content">
                      <h1 className="MobileCapabilityCarousel-card-title">{card.title}</h1>
                      <p className="MobileCapabilityCarousel-card-text">{card.content}</p>
                  </div>
              </div>
          </div>
        ))}
      </SwipeableViews>
      <div className="MobileCapabilityCarousel-progress">
        <p className="MobileCapabilityCarousel-card-text-swipe">{cards[index].swipe}</p>
        <div className="MobileCapabilityCarousel-progressDots">
        <div className={index===0?"MobileCapabilityCarousel-progressDots-dotActive":"MobileCapabilityCarousel-progressDots-dotInActive"} />
        <div className={index===1?"MobileCapabilityCarousel-progressDots-dotActive":"MobileCapabilityCarousel-progressDots-dotInActive"} />
        <div className={index===2?"MobileCapabilityCarousel-progressDots-dotActive":"MobileCapabilityCarousel-progressDots-dotInActive"} />
        <div className={index===3?"MobileCapabilityCarousel-progressDots-dotActive":"MobileCapabilityCarousel-progressDots-dotInActive"} />
        </div>
      </div>
  </div>
  );
}

export default MobileCapabilityCarousel;