import React from "react";
import "./Introduction.css";
import { ContactForm } from "./ContactForm";

export const Introduction = () => {
  return (
    <div className="Introduction" id="homepage">
      <div className="Introduction-backgroundOverlayDark3DEffect" />
      <div className="Introduction-overlay-text">
        <div className="Introduction-overlay-text-content">
          <h1>
            READY TO BRING
            <br className="titleIntroBreak" />
            YOUR VISION TO LIFE?
          </h1>
          <p>
            Through rapid iteration cycles of just 3 weeks, we develop branded
            mockups and working prototypes to validate your vision, secure
            funding, and bring promising concepts to market in no time. So the
            next time you pitch your vision and someone says you've lost your
            marbles, we've got your back.
          </p>
          <ContactForm />
        </div>
      </div>
      <div className="Introduction-overlay-image">
        <div className="Introduction-overlay-image-astronautImage">
          <img
            src="https://i.ibb.co/C62Dq6J/Astronaut-Midjourney-Final-Only-Astronaut.webp"
            alt="Astronaut looking into neon lit marble - Larger version"
            className="astronautLarge"
          />
          <img
            src="https://i.ibb.co/C62Dq6J/Astronaut-Midjourney-Final-Only-Astronaut.webp"
            alt="Astronaut looking into neon lit marble - Small version"
            className="astronautSmall"
          />
        </div>
      </div>
    </div>
  );
};

